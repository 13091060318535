import styled from "styled-components";
import { useLocation } from 'react-router-dom';
import { supabase } from "../supabaseClient";
// import { useState} from "react";
import Navbar from "../components/Navbar";
import { Helmet } from 'react-helmet';
import PixelTracking from "../components/PixelTracking";
import GlobalStyles from '../GlobalStyles'; // Import the global styles
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Container = styled.section`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center; /* Center content vertically */
  flex-direction: column; /* Stack items vertically */
  min-height: calc(100vh - 90px); /* Subtract Navbar height from viewport height */
  background-color: #e6f7ff; /* Specified background color */
`;

const Title = styled.p`
  font-size: 32px;
  font-weight: bold;
  color: #111d57;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 5px;
`;

const Subtitle = styled.p`
  color: #666;
  font-size: 24px;
  line-height: 1.5;
  margin-top: 5px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;


// const ReferrerTitle = styled.p`
//   font-size: 28px;
//   font-weight: bold;
//   color: #111d57;
//   line-height: 1.5;
//   margin-bottom: 5px;
//   @media (max-width: 768px) {
//     font-size: 24px;
//   }
// `;

// const ReferrerSubtitle = styled.p`
//   color: #666;
//   font-size: 20px;
//   line-height: 1.5;
//   margin-top: 5px;
//   text-align: center;
//   @media (max-width: 768px) {
//     font-size: 18px;
//   }
// `;

// const EmailSection = styled.section`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   background-color: #e6f7ff;
//   padding: 50px 20px 60px 20px; 
//   text-align: center;
//   width: 100%; /* Changed from 100vw to 100% */
//   @media (max-width: 768px) {
//     padding: 48px 13px 48px 13px; /* Reduced side padding for mobile */
//     width: 90%; 
// `;

// const EmailForm = styled.form`
//   display: flex;
//   justify-content: center;
//   gap: 10px;
//   margin-bottom: 40px;
// `;

// const EmailInput = styled.input`
//   padding: 10px;
//   border: 2px solid #111d57;
//   border-radius: 5px;
//   font-size: 20px;
//   background-color: #e6f7ff;
//   @media (max-width: 768px) {
//     text-align: center;
//     font-size: 16px;
//   }
// `;

// const EnterButton = styled.button`
//   padding: 10px 20px;
//   background-color: #284389;
//   border: none;
//   border-radius: 5px;
//   color: white;
//   cursor: pointer;
//   font-family: "Source Sans Pro";
//   font-size: 18px;
//   font-weight: bold;
//   box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #111d57;
//   }
// `;

// const ErrorText = styled.p`
//   color: red;
//   font-size: 18px;
// `;

const SignatureSuccess = () => {
  const location = useLocation();
  // const [email, setEmail] = useState("");
  // const [error, setError] = useState("");
  // const [stage, setStage] = useState("initial");
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email') || '';
  const startDateString = queryParams.get('startdate') || '';
    // const subscriptionQuote = queryParams.get('monthlyquote') || '';

  //Uncomment this part to redirect to CreateAccount screen
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(`/create-account?email=${encodeURIComponent(email)}`);
    }, 3000); // Wait for 3 seconds (3000 milliseconds)

    return () => clearTimeout(timeout); // Cleanup timeout on component unmount
     // eslint-disable-next-line 
  }, [navigate]);



  //update supabase to show that user has completed the full pipeline of signup
  if (process.env.REACT_APP_TESTING !== "true") {
  const supabasePromise = supabase
    .from("user-profiles")
    .update({ signup_complete : true })
    .eq("email", email);

  supabasePromise.then(({ error }) => {
    if (error) {
      console.error('Error:', error.message);
    } else {
      console.log('Successfully logged complete user to Supabase.');
    }
  });

  const supabasePromise2 = supabase
  .from("service-accounts")
  .update({ signup_complete: true })
  .eq("email", email);

  supabasePromise2.then(({ error }) => {
    if (error) {
      console.error('Error:', error.message);
    } else {
      console.log('Successfully logged complete user to Supabase.');
    }
  });
  }

  const parts = startDateString.split('-');
  
  // Extract year, month, and day from parts
  const year = parseInt(parts[0]);
  const month = parseInt(parts[1]);
  const day = parseInt(parts[2]);
  
  // Create a new Date object (month - 1 because months are zero-indexed in JavaScript)
  const dateObj = new Date(year, month - 1, day);
  
  // Format the date as "Month Day, Year"
  const options:  Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = dateObj.toLocaleDateString('en-US', options);

  // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setEmail(event.target.value);
  //   setError("");
  // };
  // const validateEmail = async (email: string) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  //   //check if referrer already has an account
  //   // const { data } = await supabase
  //   //   .from('user-profiles')
  //   //   .select(email)
  //   //   .eq("email", email)

  //   if (!emailRegex.test(email)) {
  //     return false;
  //   }

  //   const domain = email.split('@')[1];
  //   try {
  //     const lookup = await fetch(`https://dns.google/resolve?name=${domain}&type=MX`);
  //     const result = await lookup.json();
  //     return result.Answer !== undefined;
  //   } catch (e) {
  //     console.error("DNS lookup failed:", e);
  //     return false;
  //   }
  // };

  // const handleSubmit = async (event: React.FormEvent) => {
  //   event.preventDefault();
  //   if (!(await validateEmail(email))) {
  //     setError("Please enter a valid email address.");
  //   } else {
  //     setError("");
  //     addEmail(email);
  //     setStage("ReferrerSubmitted");
  //   }
  // };

  // const addEmail = async (email: string) => {
  //     const { error } = await supabase
  //       .from("referral-campaign")
  //       .insert({ referring_email: email,referred_email: referredEmail })
  //       .select();

  //     if (error) {
  //       console.error("Error updating data: ", error);
  //     }
  //      else {
  //     console.error("Failed to save referrer email");
  //   }
  // };

  return (
    <>
    <GlobalStyles /> {/* Include global styles */}
    <Helmet>
    <PixelTracking />
   </Helmet> 

    <main>
    <div>
    <Navbar />
    <Container>
        <Title>E-Signature Successful</Title>
        <Subtitle> Enjoy your limitless clean energy subscription! <br />
          Please remove auto-pay from your PG&E account. Starting  {formattedDate}, your bills are on us. 
          </Subtitle>
        {/* <EmailSection>
              <ReferrerTitle>Did someone refer you to EcoTrove?</ReferrerTitle>
              <ReferrerSubtitle>
              We will give both you and your referrer a $30 subscription discount.<br />
              Enter the email of your EcoTrove referrer to claim the discount:
              </ReferrerSubtitle>
              <EmailForm onSubmit={handleSubmit}>
                <EmailInput
                  type="email"
                  placeholder="Your referrer's email..."
                  value={email}
                  onChange={handleInputChange}
                  aria-describedby="emailError"
                />
                <EnterButton type="submit">Enter</EnterButton>
              </EmailForm>
              {error && <ErrorText id="emailError">{error}</ErrorText>}
              <ReferrerSubtitle>
              You can also earn $30 for each friend you refer going forward! <br />
              Just tell them to add your email after signing up for EcoTrove
              </ReferrerSubtitle>
              </EmailSection> */}
    </Container>
    </div>
  </main>
  </>
  );
};

export default SignatureSuccess;
